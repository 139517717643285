<div class="modal-container">
    <div *ngIf="pageType === 'mother_maiden_name'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button *ngIf="!pageType === 'final_success'" mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>

        <form [formGroup]="formInput">
            <!-- <div class="col-sm-12 col-lg-12 col-md-12 col-xs-12 p-0 onFocusPopup"> -->
                <mat-form-field  class="w-100">
                  <input [type]="hide2 ? 'password' : 'text'" matInput placeholder="Mother's maiden name"
                    formControlName='mother_maiden_name' [errorStateMatcher]="errorMatcherNew" required>
                  <span mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon class='eyeview'>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
                  <mat-error *ngIf="formInput.get('mother_maiden_name').errors?.required">Enter Mother's maiden name
                  </mat-error>
                </mat-form-field>
                
              <!-- </div> -->
              <!-- <div class="col-sm-12 col-lg-12 col-md-12 col-xs-12 p-0"> -->
                <mat-form-field  class="w-100" [ngClass] = "{'error-mat-undeline': confirmError}">
                  <input matInput type="password" [type]="hide3 ? 'password' : 'text'" placeholder="Confirm mother's maiden name"
                    formControlName='confirm_mother_maiden_name' [errorStateMatcher]="errorMatcherCon" required>
                  <span mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon class='eyeview'>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
                  <div class="error-mat" *ngIf="confirmError">Confirm Mother's maiden name</div>
                  <mat-error *ngIf="formInput.hasError('ConfirmpasswordsDoNotMatch')">Mother's maiden name and confirm mother's maiden name should be same
                  </mat-error>
                </mat-form-field>
              <!-- </div> -->

            
            <!-- <mat-form-field class="w-100">
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Mother's maiden name" formControlName='mother_maiden_name' required>
                <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon class="eyeview pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                <mat-error *ngIf="formInput.get('mother_maiden_name').errors?.required">
                 Enter Mother's maiden name</mat-error>
            </mat-form-field>

            <mat-form-field class="col-12" [ngClass] = "{'error-mat-undeline': confirmError}">
                <input matInput [type]="confirm_hide ? 'password' : 'text'" placeholder="Mother's maiden name" formControlName='confirm_mother_maiden_name' required>
                <span mat-icon-button matSuffix (click)="confirm_hide = !confirm_hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirm_hide">
                    <mat-icon class="eyeview pointer">{{confirm_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                <div class="error-mat" *ngIf="confirmError">Confirm password</div>
                <mat-error *ngIf="formInput.hasError('ConfirmpasswordsDoNotMatch')">Mother's maiden name and mother's maiden name should be same
                </mat-error>
              </mat-form-field> -->

            <!-- <mat-form-field class="w-100">
                <input matInput [type]="confirm_hide ? 'password' : 'text'" placeholder="Mother's maiden name" formControlName='confirm_mother_maiden_name' required>
                <span mat-icon-button matSuffix (click)="confirm_hide = !confirm_hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirm_hide">
                    <mat-icon class="eyeview pointer">{{confirm_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                <mat-error *ngIf="formInput.get('confirm_mother_maiden_name').errors?.required">
                 Enter Mother's maiden name and mother's maiden name should be same</mat-error>
            </mat-form-field> -->

            <button class="blue_button float-end me-3" mat-raised-button type="submit" [disabled]="!this.formInput.valid" (click)="submit()">Submit</button>
            <button class="float-end me-4" mat-raised-button (click)="close()">Close</button>

        </form>
    </div>

    <div *ngIf="pageType === 'app_in_progress'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="goToHome()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <h4>An application of same type is already in progress using this email ID. Please check your email for details and either continue with the existing application or delete it and proceed with a new one.</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>


    <div *ngIf="pageType === 'email_verified'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="goToHome()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <h4>The verification link has either expired or has been successfully verified.</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'cancel_application'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <form [formGroup]="cancelForm">
            <mat-form-field class="w-100">
                <textarea class="commentBox" type="textarea" rows="4" #message maxlength="256" formControlName="reason" required></textarea>
                <mat-error *ngIf="cancelForm.get('reason').errors?.required">
                Enter Reason</mat-error>
            </mat-form-field>
            <button class="blue_button float-end me-3" mat-raised-button type="submit" (click)="cancelApp()">Submit</button>
            <button class="float-end me-4" mat-raised-button (click)="close()">Close</button>

        </form>
    </div>

    <div *ngIf="pageType === 'PERMISSION_DENIED'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>
    <div *ngIf="pageType === 'POSITION_UNAVAILABLE'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>
    <div *ngIf="pageType === 'TIMEOUT'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>
    <div *ngIf="pageType === 'Error'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'FULL_VIEW_IMAGE'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <img class="full-image" src="{{siteImageUrl}}" alt="{{siteImageName}}">
        <button class="blue_button float-end mt-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'final_success'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToStandardcWeb()">OK</button>
    </div>


    <div *ngIf="pageType === 'saveApplication'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'certification_signature'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <label [innerHTML]="certification"></label>
        <form [formGroup]="signatureForm">
        <div class="form-check mt-3 mb-3">
            <input class="form-check-input" type="checkbox" formControlName="iconfirm" required>
            <label class="form-check-label" for="iconfirm">I confirm<span class="text-danger">*</span></label>
            <div *ngIf="signatureForm.get('iconfirm')?.invalid" class="text-danger small">
                I confirm is required.
              </div>
        </div>

    </form>
        <p style="font-weight: bold;">Signature</p>
        <div class="signature-container">
            <canvas #signaturePadCanvas></canvas>
            <label class="mt-4">Name: {{applicant_name}}</label>
            <div class="">
              <button type="button" class="mat-focus-indicator mat-raised-button mat-button-base bg-danger text-white" (click)="clearSignature()">Clear</button>
              <button type="button" class="blue_button ms-4" mat-raised-button (click)="triggerUpload()">
                Submit
              </button>
              <button class="float-end" mat-raised-button (click)="close()">Cancel</button>
            </div>
          </div>
        <!-- <app-custom-signature (signatureSaved)="onSignatureSaved($event)" (signatureCleared)="onSignatureCleared()"></app-custom-signature> -->


    </div>

</div>