import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import SignaturePad from 'signature_pad';
import { ApiService } from 'src/app/services/api.service';

class CrossFieldErrorMatcherCon implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.hasError('ConfirmpasswordsDoNotMatch');
  }
}

@Component({
  selector: 'app-basic-popup',
  templateUrl: './basic-popup.component.html',
  styleUrls: ['./basic-popup.component.css']
})
export class BasicPopupComponent implements OnInit, AfterViewInit {
    @ViewChild('signaturePadCanvas', { static: false }) signaturePadCanvas!: ElementRef<HTMLCanvasElement>;
    private signaturePad!: SignaturePad;
    clickedPhoto: File;
    private padding = 30;
    site_visit_sign: any;
    site_visit_id: string;
    applicant_name: string;
    errorMatcherCon = new CrossFieldErrorMatcherCon();
  pageType = '';
  formInput: FormGroup;
  pageTitle = '';
  message = '';
  cancelForm: FormGroup;
  siteImageUrl: any;
  siteImageName: any;
  formControl: any;
  signatureForm: FormGroup;
  certification: any;
  hide2=true;
  hide3=true;
  signatureData: any;
  
  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 

      private formBuilder: FormBuilder
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    , private cookieService: CookieService
    ) {
      this.formInput = this.formBuilder.group({
        mother_maiden_name: new FormControl('', [Validators.required]),
        confirm_mother_maiden_name: new FormControl('', [Validators.required])
      }, { validator: [this.verifyPasswordValidate] });

    this.cancelForm = this.formBuilder.group({
      reason: new FormControl(''),
  });
  this.signatureForm = this.formBuilder.group({
    iconfirm: [false, Validators.requiredTrue]
  });
  }

  ngOnInit(): void {
    this.applicant_name = localStorage.getItem('firstname')+ ' '+localStorage.getItem('lastname');
  
    this.pageType = this.data['pageType'];
    this.pageTitle = this.data['pageTitle'];
    this.certification = this.data['certification'];
    if(this.data['message']){
      this.message = this.data['message'];
    }
    if(this.pageType === 'FULL_VIEW_IMAGE'){
      this.getImageView();
    }
    
  }

    // check password
    verifyPasswordValidate(form: FormGroup) {
      const condition = form.get('confirm_mother_maiden_name').value !== form.get('mother_maiden_name').value;
      return condition ? { ConfirmpasswordsDoNotMatch: true } : null;
    }

    get confirmError() {   
      return this.formInput.controls.confirm_mother_maiden_name.invalid
        && this.formInput.controls.confirm_mother_maiden_name.touched
        && !this.formInput.hasError('ConfirmpasswordsDoNotMatch');
    }

  close() {
    this.dialogRef.close();
  }

  goToHome(){
    this.dialogRef.close(true);
  }

  goToStandardcWeb(){
    window.open('https://www.standardc.io/',"_self");
  }

  submit() {
    let mother_maiden_name = this.formInput.get('mother_maiden_name')?.value;
    if(!mother_maiden_name){
      this._api.openSnackBar("Enter Mother's maiden name", 'Error');
      return
    }
    if (this.formInput.valid) {
      this.dialogRef.close(mother_maiden_name);
    }
  }

  cancelApp() {
    let reason = this.cancelForm.get('reason')?.value;
    if(!reason){
      this._api.openSnackBar("Enter Reason", 'Error');
      return
    }
    if (this.cancelForm.valid) {
      this.dialogRef.close(reason);
    }
  }

  getImageView(){
    const apiData = {
      site_visit_id: this.data['site_visit_id'],
      df_sitevisit_attachment_id: this.data['image_id'],
      latitude: localStorage.getItem('latitude'),
      longitude:  localStorage.getItem('longitude')
    };
    this.spinner.show();
    this._api.functionPOST('web/getSiteVisitAttachment', apiData).subscribe((response) => {
      // console.log('-----------ressss', response);
      this.siteImageUrl = response['data']['fileurl'];
      this.siteImageName = response['data']['attach_name'];
      this.spinner.hide();
      });
  }


  ngAfterViewInit(): void {
      localStorage.removeItem('savedSignature');
      this.initializeSignaturePad();
      window.addEventListener('resize', this.drawLine.bind(this));
      this.restoreSignature();
    }
  
    onSignatureEnd() {
      const signatureData = this.signaturePad.toDataURL();
      localStorage.setItem('savedSignature', signatureData); // Save it in local storage
    }
    
  
    @HostListener('window:resize')
    @HostListener('window:scroll')
    restoreSignature() {
      const savedSignature = localStorage.getItem('savedSignature');
      console.log('-----ffff', savedSignature);
      if (savedSignature && this.signaturePad) {
        this.signaturePad.fromDataURL(savedSignature);
      }
      // this.adjustCanvas();
      // this.drawLine();
    }
    
    adjustCanvas() {
      const canvas = this.signaturePadCanvas.nativeElement;
      const parentWidth = canvas.parentElement?.clientWidth || window.innerWidth;
      canvas.width = parentWidth;
      canvas.height = 200;
    }
  
    drawLine() {
      if(!this.signaturePadCanvas){
        return;
      }
      const canvas = this.signaturePadCanvas.nativeElement;
      const context = canvas.getContext('2d');
      if (context) {
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const midY = canvasHeight / 1.2;
        context.clearRect(0, 0, canvasWidth, canvasHeight);
        context.strokeStyle = '#8f8f7c';
        context.lineWidth = 1;
        context.beginPath();
        context.moveTo(this.padding, midY);              
        context.lineTo(canvasWidth - this.padding, midY); 
        context.stroke();
      }
    }
  
    initializeSignaturePad(): void {
      if(!this.signaturePadCanvas){
        return;
      }
      const canvas = this.signaturePadCanvas.nativeElement;
      const ratio = Math.max(window.devicePixelRatio || 1, 1); // Device Pixel Ratio
      canvas.width = canvas.offsetWidth * ratio; // Set the canvas width to match the display size
      canvas.height = canvas.offsetHeight * ratio; // Set the canvas height to match the display size
      canvas.getContext('2d')?.scale(ratio, ratio); // Scale the canvas context to match DPI
  
      // Initialize SignaturePad
      this.signaturePad = new SignaturePad(canvas);
      this.signaturePad.penColor = "rgba(18,18,221,255)";
  
      // Load existing value if available
      // if (this.formControl.value) {
      //   this.signaturePad.fromDataURL(this.formControl.value);
      // }
       // Attach event listener to capture signature when completed
      // Listen for signature completion
      this.signaturePad.addEventListener("endStroke", () => this.onSignatureEnd());
      this.adjustCanvas();
      this.drawLine();
    }
  
    ngOnDestroy(): void {
      if (this.signaturePad) {
        this.signaturePad.off();
      }
      window.removeEventListener('resize', this.drawLine.bind(this));
    }
    clearSignature(): void {
      this.signaturePad.clear();
      localStorage.removeItem('savedSignature');
      this.initializeSignaturePad();
      window.addEventListener('resize', this.drawLine.bind(this));
    }
  
    convertDataURLToBlob(dataURL: string): Blob {
      const parts = dataURL.split(',');
      const mimeType = parts[0].match(/:(.*?);/)[1];
      const byteString = atob(parts[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      return new Blob([uint8Array], { type: mimeType });
    }
  
    triggerUpload() {
      let iconfirm = this.signatureForm.get('iconfirm')?.value;
          if(!iconfirm){
            this._api.openSnackBar("I confirm is required.", 'Error');
            return
          }

        if (this.signaturePad && !this.signaturePad.isEmpty()) {
          const timestamp = new Date().toISOString();
          const signatureData = this.signaturePad.toDataURL(); // Base64 Image Data
          const blob = this.convertDataURLToBlob(signatureData); // Convert to Blob
          const fileName = 'signature.png';
          const file = new File([blob], fileName, { type: blob.type });
          this.spinner.show();
          const formData = new FormData();
          formData.append('instance_id', localStorage.getItem('instance_id'));
          formData.append('email', localStorage.getItem('email'));
          formData.append('file',  file);
          formData.append('capture_timestamp', timestamp);
          formData.append('latitude',  localStorage.getItem('latitude'));
          formData.append('longitude', localStorage.getItem('longitude'));
          this.spinner.show();
          this._api.functionPOST('web/uploadAppSignature', formData).subscribe((response) => {
            // this._api.openSnackBar(response['data'], 'Success');
            if(response['success']){
              this.clickedPhoto = null;
              this.spinner.hide();
              this.dialogRef.close(true);
            }else{
              this._api.openSnackBar(response['data'], 'Error');
              return
              // this.to.onClick(false,'Signature is not uploaded.');
            }
            
          });
      } else {
        this._api.openSnackBar("Signature is empty.", 'Error');
          return;
      }
      
    }
  
    getSignature(){
      const apiData = {
        instance_id: localStorage.getItem('instance_id')
      };
      this.spinner.show();
      this._api.functionPOST('web/inApp/getSignature', apiData).subscribe((response) => {
        this.site_visit_sign = response['data']['image'];
        if (this.signaturePadCanvas?.nativeElement) {
          this.initializeSignaturePad();
          window.addEventListener('resize', this.drawLine.bind(this));
        }
        this.spinner.hide();
        });
    }

}
