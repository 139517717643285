export const environment = {
  production: true,
  name: 'production',
  BACKEND : 'https://api-am.standardc.io/',
  FRONTEND : 'https://am.standardc.io/',
  //IDme Client-id
  client_id : '9a08fee0bd594a4de2de1651818678a7',
  idme_url : 'https://api.id.me',
  idme_scope:'identity',
  idme_redirect_uri : 'https://am.standardc.io/verification',
  reCaptcha2SiteKey: '6Lf2B8AqAAAAAJ2-ZoyRg0-tMF6yKn2MqYfUMmLF',
  reCaptchaSiteKey: '6Ldz-r8qAAAAABbm8JWWDYgl0Papu3YVnCoI380t',
  google_tag_id: 'G-QSQE17TR2D'
};