import { Injectable } from "@angular/core";
import { BACKEND, tokenType, urls } from "../config";
import { from, throwError, Observer, fromEvent, merge } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, pipe, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import {
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UnAuthComponent } from "../shared/popups/un-auth/un-auth.component";
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from 'src/environments/environment';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  is_allowed: boolean;
  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private cookieService: CookieService,
    private router: Router,
    private dialogRef: MatDialog,
    private spinner: NgxSpinnerService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}
  headers: any;
  isOpen: boolean = false;
  businessIdAm = "";
  messageCount = {
    count: 0,
    unread_count_details: [],
  };
  taskCount={
    total:0,
    unread_count_details: []
  }
  // check loacation permission 
  checkLocationAccess(): Observable<boolean> {
    return new Observable(observer => {
      this.spinner.show();    
      if (!navigator.geolocation) {
        alert("Geolocation is not supported by your browser.");
        observer.next(false);
        observer.complete();
        this.spinner.hide();   
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
              
          console.log("Location access granted.");
          localStorage.setItem('latitude', position.coords.latitude.toString());
          localStorage.setItem('longitude', position.coords.longitude.toString());
          observer.next(true);
          observer.complete();
          this.spinner.hide()    
        },
        (error) => {
          switch (error.code) {
            case 1: // PERMISSION_DENIED
              this.showPopup("Location Permission Denied",
                "Please enable location access in your browser settings.", "PERMISSION_DENIED");
              break;
            case 2: // POSITION_UNAVAILABLE
              this.showPopup("Location Unavailable",
                "Please ensure location services are enabled on your device.", "POSITION_UNAVAILABLE");
              break;
            case 3: // TIMEOUT
              this.showPopup("Request Timed Out",
                "The request to retrieve your location timed out. Please try again.", "TIMEOUT");
              break;
            default:
              this.showPopup("Error",
                "An unknown error occurred while accessing your location.", "Error");
          }
          observer.next(false);
          observer.complete();
          this.spinner.hide()    
        }
      );
    });
  }

  showPopup(title: string, message: string, case_type: string) {
    const dialogRef = this.dialogRef.open(BasicPopupComponent, {
      maxWidth: '600px'
      , data: {
        pageTitle: title,
        message: message,
        pageType: case_type
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
      }
    });
  }
  // GET
  functionGET(url: any, token?: any) {
    //  this.spinner.show();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: tokenType + localStorage.getItem('authToken'),
      }),
    };
    return this.http.get(BACKEND + url, httpOptions).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error)))
    );
  }
  fileReader(url: string, data: {}, mode?: string) {
    const Link = BACKEND + url;
    const header = new HttpHeaders().set(
      "Authorization",
      tokenType + localStorage.getItem('authToken')
    );
    const headers = { headers: header };
    // return this.http.post<Blob>(Link, data, { headers: header, responseType: 'blob' as 'json' })
    //   .pipe(map(response => {
    //     return response;
    //   }),
    //     catchError((error) => throwError(this.handleError(error))));

    //     for s3/////////////////////
    return this.http.post(Link, data, headers).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(this.handleError(error)))
    );
  }

  downloadFile(url: any, tag?: any) {
    if (tag === '&') {
      url = url + "&token=";
    } else {
      url = url + "?token=";
    }

    if(tag === '&') {
      window.open(BACKEND + url + this.cookieService.get('token'));
    } else {
      this.http
      .get(BACKEND + url + localStorage.getItem('authToken'))
      .subscribe((response: any) => {
        window.open(response.data.fileurl);
      });
    }
  }

  downloadNewFile(url: any) {
    let Link;
    url = url + "?token=" + localStorage.getItem('authToken');
    Link = urls["BACKEND"] + url;
    this.http
    .get(Link)
    .subscribe((response: any) => {
      window.open(response.data.fileurl);
    });
  }

  // functionPOST(url: any, data: any, token?: any): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: tokenType + localStorage.getItem('authToken'),
  //     }),
  //   };
  //   //  this.spinner.show();
  //   const Link = BACKEND + url;
  //   return this.http.post(Link, data, httpOptions).pipe(
  //     map((Response) => this.checkResponse(Response)),
  //     catchError((error) => throwError(this.handleError(error)))
  //   );
  // }


  functionPOST(url: any, data: any, v2token?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: tokenType + localStorage.getItem('authToken'),
      }),
    };
    const apiLink = BACKEND + url;
    // Trigger the reCAPTCHA token
    return new Observable((observer) => {
      if(v2token){
          let requestData: any
          if (data instanceof FormData) {
              data.append('captcha_token', v2token );
              requestData = data;
            } else {
              requestData = {
                ...data,
                captcha_token: v2token
              };
            }
          this.http.post(apiLink, requestData, httpOptions).pipe(
            map((response) => this.checkResponse(response)),
            catchError((error) => throwError(this.handleError(error)))
          ).subscribe({
            next: (response) => observer.next(response),
            error: (error) => observer.error(error),
            complete: () => observer.complete(),
          });
      }else{
        this.reCaptchaV3Service.execute(environment.reCaptchaSiteKey, 'importantAction', (captcha_token: string) => {
          // Include the reCAPTCHA token in the API request
          let requestData: any
          if (data instanceof FormData) {
              data.append('captcha_token', captcha_token );
              requestData = data;
            } else {
              requestData = {
                ...data,
                captcha_token
              };
            }
          this.http.post(apiLink, requestData, httpOptions).pipe(
            map((response) => this.checkResponse(response)),
            catchError((error) => throwError(this.handleError(error)))
          ).subscribe({
            next: (response) => observer.next(response),
            error: (error) => observer.error(error),
            complete: () => observer.complete(),
          });
        });
      }
      
    });
  }

  
  functionDELETE(url: any, data: any, token?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: tokenType + localStorage.getItem('authToken'),
      }),
      body: data,
    };
    //  this.spinner.show();
    const Link = BACKEND + url;
    return this.http.delete(Link, httpOptions).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error)))
    );
  }

  // ToasterSnack
  openSnackBar(message: string, type: string, duration?: number) {
    const config = new MatSnackBarConfig();
    config.duration = 8000;
    if (duration) {
      config.duration = duration;
    }
    config.politeness = "assertive";
    if (type === "Error") {
      config.panelClass = ["RED_BACKGROUND"];
    }else if (type === "Warning") {
      config.panelClass = ["WARNING_BACKGROUND"];
    } else {
      config.panelClass = ["GREEN"];
    }
    // else{
    //   config.panelClass = ['ORANGE'];
    // }
    this.snackBar.open(message, "x", config);
  }

  // Handler
  checkResponse(response: any) {
    // this.spinner.hide();
    //
    const results = response;
    //
    if (results["success"]) {
      return results;
    } else {
      //
      // throw new Error(results);
      return { error: results["error"] };
    }
  }
  handleError(error: any) {
    if(error.error.errorDescription){
      error.error.error =  error.error.errorDescription;
    }
    let isOffline;
    this.spinner.hide();
    this.createOnline$().subscribe((status) => {
      isOffline = status;
    });
    switch (error.status) {

      case 0:
      case 408:
        isOffline && this.openSnackBar("Try again", "Error");
        break;
      case 401: 
        if (this.router.url.includes("/createaccount?")) {
          this.dialogRef.closeAll();
          // this.showUnauthMessage("Invite has been cancelled");
        }
        if (this.router.url.includes("/invite-user?") && error["error"]["error"]=='UnAuthorized - Invalid or cancelled request') {
          this.dialogRef.closeAll();  
          // this.showUnauthMessage('Invalid or Cancelled  Request ');
          break
        }
        if (this.router.url != "/" && isOffline) {
          if (!this.isOpen) {
            this.dialogRef.closeAll();
            // this.showUnauthMessage("Session Timeout / Unauthorized");
          }
        }
        
        break;
      case 455:
        if (!this.isOpen && isOffline) {
          this.dialogRef.closeAll();
          // this.showUnauthMessage(
          //   "Link Expired , Please Contact StandardC Admin"
          // );
        }
        break;
      case 453:
      case 452:
        // ignore messages
        break;
      case 454:
        isOffline &&
          this.openSnackBar(error["error"]["error"]["message"], "Error");
        break;
      case 0:
          this.openSnackBar('No Internet Connection Available', 'Error');
          break;
      case 403:
        isOffline && this.openSnackBar(error["error"]["error"], "Error");
        // this.router.navigate(['dashboard']);
        break;
      default:
        isOffline && this.openSnackBar(error["error"]["error"], "Error");
        break;
    }
    return error["error"];
  }
  // logOut() {
  //   this.functionPOST("user/logout", {}).subscribe((res) => {
  //     this.authService.logout();
  //   });
  // }

  // showUnauthMessage(message) {
  //   if (!this.isOpen) {
  //     this.isOpen = true;
  //     this.authService.logout();
  //     const dialogRef = this.dialogRef.open(UnAuthComponent, {
  //       width: "400px",
  //       data: message,
  //     });
  //     dialogRef.afterClosed().subscribe((result) => {
  //       this.dialogRef.closeAll();
  //     });
  //   }
  // }

  /**
   * Check user's internet connection
   */
  createOnline$() {
    return merge<any>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
